import { DomainList } from "../types/domains";
import { MainNavigation } from "../types/navigation";
import { SocialNetworks } from "../types/networks";

export const siteParams = {
  footerSignature: "© 2023 Benu GmbH. Alle Rechte vorbehalten.",
};

export const siteNavigation: MainNavigation = {
  domain: DomainList.BENU,
  redirects: [],
  rewrites: [],
  topMenu: [],
  footerMenu: [],
  social: [],
};
