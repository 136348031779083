import React from "react";

const Brand = ({ size = 140, className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size / 141) * 40}
      viewBox="0 0 141 40"
      className={`${className} fill-current`}
    >
      <path
        fillRule="evenodd"
        d="M0 39.104h14.108c12.056 0 18.315-3.356 18.315-10.895 0-5.008-3.693-8.002-9.952-9.19 4.822-1.549 8.054-4.647 8.054-9.036 0-6.35-4.412-9.603-16.109-9.603H0v.31l3.694 3.252v31.703L0 38.794v.31zm11.286-20.911V2.445h2.822c5.49 0 8.465 2.427 8.465 7.797 0 5.938-3.386 7.95-8.26 7.95h-3.027zm4.053 2.014c6.31 0 9.132 3.097 9.132 8.415 0 5.783-3.027 8.468-9.645 8.468h-3.54V20.207h4.053zM49.15 39.62c5.078 0 8.772-2.168 11.542-5.008v-.672c-2.052 1.136-4.156 1.653-6.464 1.653-5.746 0-8.773-3.253-9.799-7.9l14.93-3.098 2-.413c-1.282-7.177-5.438-10.946-11.748-10.946-8.158 0-13.083 6.144-13.083 13.528 0 7.383 5.182 12.856 12.621 12.856zm-5.131-16.11c0-4.8 1.744-8.673 5.284-8.673 2.514 0 3.95 3.304 4.31 8.313l-9.39 3.355c-.153-.929-.204-1.961-.204-2.994zM77.98 38.795l-3.694-2.891V18.915l.154-.051c2.103-.93 4.053-1.704 6.258-1.704 3.387 0 4.515 1.704 4.515 5.731v13.012l-3.693 2.89v.31h14.724v-.31l-3.694-2.89V23.2c0-7.28-2.052-9.759-7.388-9.759-2.976 0-5.9 1.085-10.722 3.615l-.154.103v-3.408h-11.03v.31l3.694 3.82v18.02l-3.694 2.892v.31H77.98v-.31zm39.333-2.788l.205-.103v3.2h10.825v-.309l-3.694-3.046V13.649l-11.389.104v.31l4.002 2.89V34.2c-1.899.93-3.591 1.6-5.438 1.6-3.386 0-4.566-1.703-4.566-5.731V13.649l-11.03.104v.31l3.693 2.89v12.805c0 6.506 1.129 9.656 7.234 9.656 3.591 0 6.772-1.188 10.158-3.408zm18.777 3.614c2.257 0 4.053-1.445 4.053-3.975 0-2.324-1.796-3.924-4.053-3.924-2.258 0-4.053 1.6-4.053 3.924 0 2.53 1.795 3.975 4.053 3.975z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Brand;
